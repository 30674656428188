import classic from '../../utils/images/programs/classic.png';
import classicColored from '../../utils/images/programs/classic-colored.png';
import tanec from '../../utils/images/programs/dikiy-tanec.png';
import tanecColored from '../../utils/images/programs/dikiy-tanec-colored.png';
import mensClub from '../../utils/images/programs/mens-club.png';
import mensClubColored from '../../utils/images/programs/mens-club-colored.png';
import amazon from '../../utils/images/programs/pokori-amazonku.png';
import amazonColored from '../../utils/images/programs/pokori-amazonku-colored.png';
import pup from '../../utils/images/programs/prishel-uvidel-podaril.png';
import pupColored from '../../utils/images/programs/prishel-uvidel-podaril-colored.png';
import ritual from '../../utils/images/programs/ritual.png';
import ritualColored from '../../utils/images/programs/ritual-colored.png';
import vlast from '../../utils/images/programs/vlast.png';
import vlastColored from '../../utils/images/programs/vlast-colored.png';
import vodopad from '../../utils/images/programs/vodopad.png';
import vodopadColored from '../../utils/images/programs/vodopad-colored.png';
import voitel from '../../utils/images/programs/voitelnic.png';
import voitelColored from '../../utils/images/programs/voitelnic-colored.png';
import ukroshenie from '../../utils/images/programs/ukroshenie.png';
import ukroshenieColored from '../../utils/images/programs/ukroshenie-colored.png';
import secret from '../../utils/images/programs/secret.png';
import secretColored from '../../utils/images/programs/secret-colored.png';
import tebeMne from '../../utils/images/programs/tebe-mne.png';
import tebeMneColored from '../../utils/images/programs/tebe-mne-colored.png';
import iskusitel from '../../utils/images/programs/iskusitelnica.png';
import iskusitelColored from '../../utils/images/programs/iskusitelnica-colored.png';
import amazonKiss from '../../utils/images/programs/amazon-kiss.png';
import amazonKissColored from '../../utils/images/programs/amazon-kiss-colored.png';
import dop from '../../utils/images/programs/dop.png';
import dopColored from '../../utils/images/programs/dop-colored.png';


export const programms  = [
  {
    image: ritual,
    imageColored: ritualColored,
    title: 'Таинственный ритуал',
    price: '8000',
    time: '90 мин',
    description: '<li>Выбор девушки с завязанными глазами</li>\n' +
        '<li>Принятие душа в полной темноте</li>\n' +
        '<li>Эротическая часть, которая проводятся в «абсолютной темноте»</li>\n' +
        '<li>Совместное принятие душа</li>\n' +
        '<li>Чайная церемония и долгожданное знакомство с таинственной незнакомкой</li>'
  },
  {
    image: vlast,
    imageColored: vlastColored,
    title: 'Власть незнакомки',
    price: '20000',
    time: '90 мин',
    description: '<li>Душ до и после программы</li>\n' +
        '<li>Игрушки в сторону гостя (наручники, связывание, плетка)</li>\n' +
        '<li>Возможность дарить девушки ласки по телу, включая поцелуи массаж по маслу горячей свечей БДСМ (связывание, фиксация, жесткое обращение, женская доминация)</li>\n' +
        '<li>Прикосновения</li>\n' +
        '<li>Цветок лотоса</li>\n' +
        '* Подробности проведения программы обсуждаются с гостем при личной встрече. \n' +
        'Программа сугубо индивидуальная и не имеет определенного сценария'
  },
  {
    image: vodopad,
    imageColored: vodopadColored,
    title: 'Водопад желаний',
    price: '16000',
    time: '180 мин',
    description: '<li>Душ с девушкой до программы</li>\n' +
        '<li>Расслабляющая часть с доведением до пика удовольствия</li>\n' +
        '<li>Принятие джакузи с девушкой</li>\n' +
        '<li>Расслабляющая часть в пене с доведением до пика удовольствия</li>\n' +
        '<li>Релакс всего тела</li>\n' +
        '<li>И снова расслабляющая часть с доведением до пика удовольствия</li>\n' +
        '<li>Пять дополнительных услуги на выбор уже включены в программу</li>\n' +
        '<li>Неограниченное количество расслабления так же включено в программу!</li>\n' +
        '<li>По окончанию вторичное принятие душа с девушкой и при желании снова расслабляющая часть в аква-пенном исполнении</li>'
  },
  {
    image: voitel,
    imageColored: voitelColored,
    title: 'Игры воительниц',
    secondPrice: '24000',
    secondTime: '120 мин',
    price: '31000',
    time: '120 мин',
    description: '<li>Душ с двумя девушками</li>\n' +
        '<li>Лесби шоу (смотреть нельзя трогать) запятую поставьте сами</li>\n' +
        '<li>Пип - шоу</li>\n' +
        '<li>Эротическая часть</li>\n' +
        '<li>Релакс «Сакура»</li>\n' +
        '<li>Релакса «Лингама»</li>\n' +
        '<li>Классический релакс</li>\n' +
        '<li>Неограниченное количество расслаблений</li>\n' +
        '<li>Душ в конце программы</li>\n'
  },
  {
    image: ukroshenie,
    imageColored: ukroshenieColored,
    title: 'Укрощение строптивой',
    price: '12000',
    time: '80 мин',
    description: '<li>Принятие пенного душа с девушками</li> \n' +
        '<li>Релакс всего тела</li> \n' +
        '<li>Расслабляющая часть с доведением до пика удовольствия 4 дополнительные услуги на выбор </li>\n' +
        '<li>По окончанию программы вторичное принятие душа с девушками </li>\n' +
        '<li>Часто сексуальных красивых девушек ассоциируют с кошками: пластика, грация, любовь к ласке – все это их объединяет. Выберите свою кошечку и проведите с ней время по сценарию программы</li>'
  },
  {
    image: pup,
    imageColored: pupColored,
    title: 'Пришел. увидел, подарил',
    price: '11000',
    time: '90 мин',
    description: '<li>Принятие душа вместе</li> \n' +
        '<li>Эротическая часть с доведением до пика наслаждения</li>\n' +
        '<li>Релакс всего тела</li> \n' +
        '<li>Аквапеннный боди массаж </li>\n' +
        '<li>Вторая эротическая часть с доведением до полного релакса, с особой техникой «массаж лингама» </li>\n' +
        '<li>Вишенкой на торте данной програмы является подарок девушке – «жемчужное ожерелье»</li>\n' +
        '<li>Совместное принятие душа по окончанию программы</li>'
  },
  {
    image: tanec,
    imageColored: tanecColored,
    title: 'Дикий танец',
    price: '6000',
    time: '60 мин',
    description: '<li>Душ до начала программы</li>\n' +
        '<li>Релакс ног и ягодиц в 4 руки</li>\n' +
        '<li>Эротическая часть</li>\n' +
        '<li>Релакс всего тела</li>\n' +
        '<li>Эротическая часть</li>\n' +
        '<li>Принятие душа</li>\n' +
        '* Не распространяется акция кончить за 10 минут'
  },
  {
    image: amazon,
    imageColored: amazonColored,
    title: 'Покори амазонку',
    price: '30000',
    time: '120 мин',
    description: '<li>Нет ограничения на какую-либо дополнительную услугу, все включено!</li>\n' +
        '<li>15 дополнительных услуг. Все желания будут исполнены!</li>\n' +
        '<li>Расслабление нон-стоп</li>\n' +
        'Программа для тех, кто поистине увлечен жизнью и любит власть. Созданная атмосфера сделает акцент на Вашем статусе и достоинствах, проявит Вас как истинного властелина, расслабит и очарует. Самая прекрасная леди создаст гармоничный дуэт, в которым Вы почувствуете себя в центре эротического торнадо. Шоу-программа составлена при участии сексолога и специалиста по техникам тантры. Девушки прошли специальное обучение'
  },
  {
    image: secret,
    imageColored: secretColored,
    title: 'Наш маленький секрет',
    price: '9000',
    time: '90 мин',
    description: '<li>Душ до и после программы</li>\n' +
        '<li>Массаж всего тела</li>\n' +
        '<li>Массаж простаты</li>\n' +
        '<li>Эротический массаж</li>'
  },
  {
    image: mensClub,
    imageColored: mensClubColored,
    title: "Men's club Safari",
    price: '31500',
    time: '120 мин',
    description: '<li>Приватный танец от нашей соблазнительницы</li>\n' +
        '<li>Душ до и после программы</li>\n' +
        '<li>Пенное джакузи</li> \n' +
        '<li>Расслабляющий массаж по всему телу</li>\n' +
        '<li>«Body» массаж с маслом</li>\n' +
        '<li>Откровенное Пип — шоу с игрушкой девушки на Ваших глазах и Вашим участием</li>\n' +
        '<li>Все имитации сексуальных поз, в том числе и 69</li>\n' +
        '<li>Имитация оральных ласк от нашей искусительницы</li>\n' +
        '<li>Королевский массаж Лингама</li>\n' +
        '<li>Поцелуи по телу гостя от нашей девушки</li>\n' +
        '<li>Пожелания гостя, согласно правилам салона</li>\n' +
        '<li>Возможно одаривать девушку ласками по телу и поцелуями в том числе</li>\n' +
        '<li>На выбор одна из техник: Массаж Апельсинами, Шелковое полотно, Техника Лисичка</li>\n' +
        '<li>Неограниченные релаксации</li>\n' +
        '<li>Комплимент от заведения: Шампанское или Паровой коктейль на выбор</li>\n' +
        '<li>Чайная или кофейная церемония на выбор</li>'
  },
  {
    image: tebeMne,
    imageColored: tebeMneColored,
    title: "Я тебе, ты мне",
    price: '10000',
    time: '60 мин',
    description: '<li>Встреча гостя в белье</li>\n' +
        '<li>Душ до и после программы</li>\n' +
        '<li>Расслабляющий массаж </li>\n' +
        '<li>Эротический массаж</li>\n' +
        '<li>Взаимность</li>\n' +
        '<li>Релакс  в позе 69</li>\n' +
        'Эта программа – настоящее комбо нежности и страсти, физического и эстетического удовольствия. Подойдет для любителей не только получать удовольствие, но и доставлять его избраннице'
  },
  {
    image: iskusitel,
    imageColored: iskusitelColored,
    title: "Искусительница",
    price: '8000',
    time: '60 мин',
    description: '<li>Душ с девушкой</li>\n' +
        '<li>Расслабляющий массаж тела</li>\n' +
        '<li>Различные имитации сексуальных поз</li>\n' +
        '<li>Откровенное пип - шоу</li>\n' +
        '<li>Эротическая часть </li>\n' +
        '<li>Душ после программы</li>\n' +
        '<li>Чайная церемония </li>'
  },
  {
    image: amazonKiss,
    imageColored: amazonKissColored,
    title: "Поцелуй амазонки",
    price: '8000',
    time: '60 мин',
    description: '<li>Принятие душа с девушкой</li>\n' +
        '<li>Расслабляющий массаж всего тела</li>\n' +
        '<li>Массаж «веточка сакуры»</li>\n' +
        '<li>Эротическая часть</li>\n' +
        '<li>Совместное принятие душа</li>\n' +
        'В этой программе разрешено прикасаться к девушке. Окунитесь в романтическую атмосферу любви и флирта, наслаждаясь ласкающими и будоражащими поцелуями очаровательной юной девушки'
  },
  {
    image: classic,
    imageColored: classicColored,
    title: 'Классические программы',
    descriptionList: [
      {
        title: '1 час',
        description: '<li>Душ с мастером до и после программы</li>\n' +
            '<li>Аквапенный боди массаж, классический либо расслабляющий</li> \n' +
            '<li>Массаж одной части тела на выбор (спина\\ноги)</li>\n' +
            '<li>Одна эротическая часть</li>',
        time: '60 мин',
        price1: '4000',
        price2: '6000',
        price3: '9000',
      },
      {
        title: '1,5 часа',
        description: '<p>ВАРИАНТ 1</p> \n' +
            '<li>Душ с мастером до и после программы</li>\n' +
            '<li>Аквапенный боди массаж , классический массаж всего тела</li>\n' +
            '<li>Одна эротическая часть</li>\n' +
            '<p>ВАРИАНТ 2</p> \n' +
            '<li>Душ с девушкой до и после программы</li>\n' +
            '<li>Аквапенный боди массаж</li>\n' +
            '<li>Две эротические части с доведением до пика наслаждения </li>',
        time: '90 мин',
        price1: '5000',
        price2: '8000',
        price3: '11000',
      },
      {
        title: '2 часа',
        description: '<li>Душ с девушкой до и после программы</li>\n' +
            '<li>Аквапенный боди массаж</li>\n' +
            '<li>Классический либо расслабляющий массаж всего тела, от макушки до кончиков ног </li>\n' +
            '<li>Две эротические части с доведением до пика наслаждения</li>',
        time: '120 мин',
        price1: '6000',
        price2: '10000',
        price3: '13000',
      },
      {
        title: '3 часа',
        description: '<li>Душ с девушкой до и после программы </li>\n' +
            '<li>Аквапенный боди массаж</li>\n' +
            '<li>Классический или расслабляющий массаж \n' +
            'на ваш выбор всего тела</li>\n' +
            '<li>Неограниченное количество эротик</li>',
        time: '180 мин',
        price1: '8000',
        price2: '15000',
        price3: '20000',
      }
    ],
  },
  {
    image: dop,
    imageColored: dopColored,
    title: 'Дополнительные программы',
    description: 'Джакузи - 1500\n' +
        'Дополнительная эротика - 3000\n' +
        'Цветок лотоса  - 4000\n' +
        'Наш маленький секрет - 4000\n' +
        'Прикосновения - 2000\n' +
        'Приват танец - 2000\n' +
        'Массаж Лингама - 2000\n' +
        'Веточка Сакуры - 1500\n' +
        'Имитация оральных ласк - 2000\n' +
        'Неожиданный поцелуй \n' +
        '(с согласия девушки) – 5 000\n' +
        'Французский поцелуй (поцелуи по телу) - 2000\n' +
        'Купить белье мастера - 5000\n' +
        'Тайное желание  – 5000 \n' +
        'Шелковая фантазия  - 1500\n' +
        'Пип-шоу – 2000 /4000 \n' +
        'Фут-фетиш шоу – легкое  2000, \n' +
        'полноценное - 4000\n' +
        'Стриптиз - 3000\n' +
        'Розовый веер – 6000 / 12000\n' +
        'Дополнительная девушка к программе - 4000\n' +
        'Золотой дождь - 6000\n' +
        'Легкое доминирование (Госпожа-лайт) - 3000\n' +
        'Незнакомка - 2000\n' +
        'Сливки шоу - 3000\n' +
        'Жемчужное ожерелье - 2000\n' +
        'Массаж головы - 2000\n' +
        'Массаж стоп горячим полотенцем - 2000\n' +
        'Горячие апельсины - 1000\n' +
        'Раздеть девушку – 3 000\n' +
        'Распущенные волосы - 2000\n' +
        'Обмен ролями (массаж девушке) - 2000\n' +
        'Легкой походкой (девушка на каблуках) - 2000\n'
  }
];

